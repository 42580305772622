import React, { useEffect, useState } from "react";
import { DishWithSeparator } from "components/dish"
import { useTranslation } from "react-i18next";

const MenuSection = ({type, dishes, unavailableDish}) => {
  const { t } = useTranslation(["translation", "theme"]);

  const isMobile = typeof window !== "undefined" ? window.matchMedia('(max-width: 768px)') : {matches: true};

  const [show, setShow] = useState(!isMobile.matches);
  useEffect(() => {
    setShow(!isMobile.matches)
  }, [isMobile.matches])
  return (
    <div>
    <div className="content columns is-mobile" onClick={() => setShow(!show)}>
      <div className="column">
        <h3 className="has-text-weight-bold mb-1 is-clickable">{t([type.name, ''])}</h3>
        <span>{t([type.note, ''])}</span>
      </div>
      <div className="column has-text-right">
        <button className="button is-text px-0" onClick={() => setShow(!show)}>{!show ? t("show", {ns: "theme"}) : t("hide", {ns: "theme"})}</button>
      </div>
    </div>
    {!show && <hr className="m-0 has-background-black" style={{height: "1px"}}/>}
    <div className={!show ? "is-hidden" : ""}>
    {dishes.map((dish) =>
      <DishWithSeparator dish={dish} isUnavailable={unavailableDish.has(dish.uuid)}/>
    )}
    </div>
    </div>
  )
}

export default MenuSection
