import * as React from "react"
import { Card, Row, Col } from 'antd';
import { useTranslation } from "react-i18next";

import MenuSection from "components/menu"
import { AllergenList } from "components/allergen"

const Menu = ({menu, unavailableDish}) => {
  const { t } = useTranslation();
  return (
    <div className="card has-background-sand">
      <header className="card-header">
      <div className="p-4">
        <h2 className="title">{t([menu.name, 'Menu'])}</h2>
        <p className="subtitle">{t([menu.note, ''])}</p>
      </div>
      </header>
      <div className="card-content">
        <div className="columns is-multiline">
        {menu.types.map((type) =>
          <MenuLine key={type.uuid} allDishes={menu.dishes} type={type} allUnavailableDish={unavailableDish} />
        )}
        </div>
        <h4 className="has-text-weight-bold mb-1 is-clickable">Allergènes</h4>
        <AllergenList />
      </div>
    </div>
  )
}

const MenuLine = ({allDishes, type, allUnavailableDish}) => {
  const dishes = allDishes.filter((dish) => dish.type === type.uuid);
  const uuidDishes = dishes.map(({uuid}) => uuid);
  return (
    <div className="column is-4">
      <div className="is-hidden-desktop mt-6"></div>
      <MenuSection
        type={type}
        dishes={dishes}
        unavailableDish={allUnavailableDish}/>
    </div>
  )
}

export default Menu
