import React, { useState } from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next";
import { StaticImage } from "gatsby-plugin-image"
import Logo from 'images/logo_banner.png'
import { Layout, Menu, Grid } from 'antd';
import { NavLogo } from 'components/logo'
const { SubMenu } = Menu;
const { useBreakpoint } = Grid;


const AntHeader = Layout.Header;

const Header = ({ links }) => {
  const { t, i18n } = useTranslation();

  const lang = i18n.language;
  const languages = Object.keys(i18n && i18n.store && i18n.store.data ? i18n.store.data : {})
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  return (
    <nav className="navbar has-background-primary" role="navigation" aria-label="main navigation">
      <div className="navbar-brand is-align-items-center">
        <Link to="/" className="navbar-item">
          <NavLogo />
        </Link>
        <a role="button" class="navbar-burger has-text-light" aria-label="menu" aria-expanded="false" onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div className={"navbar-menu has-background-primary " + (isNavbarOpen ? "is-active" : "")}>
        <div className="navbar-end">
          <div className="navbar-item has-dropdown is-hoverable has-background-primary">
            <a className="navbar-link has-text-light">
            {lang ? lang.toUpperCase() : ""}
            </a>
            <div className="navbar-dropdown">
            {languages.map((language) =>
              <a className="navbar-item has-text-light" onClick={() => i18n.changeLanguage(language)} key={language.toUpperCase()}>{language.toUpperCase()}</a>
            )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
