import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet';
import { Layout, Divider, Grid } from 'antd';
import 'antd/dist/antd.css';
import 'styles/index.scss';


import Header from "sections/header";
import Footer from "sections/footer";
import Menu from "sections/menu";
import DebugData from "components/data";

const { Content } = Layout;
const { useBreakpoint } = Grid;

const MenuPage = ({data}) => {
  const menuHandle = process.env.GATSBY_ABAM_MENU;
  const links = data.allNavJson;
  const menus = data.allMenu.edges;
  const settingsMenu = data.allSettingsMenuJson.settings.map(({setting}) => setting.handle);
  //const selectedMenus = settingsMenu.map((toDisplay) => menus.find(({menu}) => menu.handle === toDisplay))
  const selectedMenus = menus.filter((menu) => menu.menu.handle === menuHandle);
  const [unavailableDish, setUnavailableDish] = useState(new Set())
  useEffect(() => {
    fetch(process.env.GATSBY_ABAM_API_AVAILABLEDISH_URL,
         {headers:{'Authorization': `Token ${process.env.GATSBY_ABAM_API_TOKEN}`}})
         .then(response => response.json())
         .then(data => {
           setUnavailableDish(new Set(data.filter(({uuid, is_available}) => !is_available).map(({uuid, is_available}) => uuid)))
         })
    const id = setInterval(() =>
      fetch(process.env.GATSBY_ABAM_API_AVAILABLEDISH_URL,
           {headers:{'Authorization': `Token ${process.env.GATSBY_ABAM_API_TOKEN}`}})
           .then(response => response.json())
           .then(data => {
             setUnavailableDish(new Set(data.filter(({uuid, is_available}) => !is_available).map(({uuid, is_available}) => uuid)))
           }), process.env.GATSBY_ABAM_API_REFRESH_INTERVAL * 1000);
    return () => clearInterval(id);
  }, []);
  const {xs} = useBreakpoint();
  return (
    <>
    <Helmet defer={false}>
      <title>Menu - La Licorne, restaurant de plage à Calvi</title>
      <meta name="description" content="La Licorne, restaurant de plage à Calvi vous propose sa carte" />
    </Helmet>
    <Header links={links.navigation} />
    <section className="section has-background-primary p-0 pb-1">
    <div className="container is-fullhd ">
      {selectedMenus.map(({menu}, index) =>
      <div key={index}>
        <Menu menu={menu} unavailableDish={unavailableDish}/>
        <hr className="has-background-primary" />
      </div>
      )}
    </div>
    </section>
    <Footer />
    </>
  )
}

export default MenuPage

export const query = graphql`
  {
    allMenu {
      edges {
        menu:node {
          id
          handle
          name
          types {
            name
            note
            uuid
          }
          note
          dishes {
            name
            handle
            description
            price
            allergens {
              type
            }
            currency
            picture
            type
            uuid
          }
        }
      }
    }
    allNavJson {
      navigation:edges {
        nav:node {
          link
          name
        }
      }
    }
    allSettingsMenuJson {
      settings:edges {
        setting:node {
          type
          handle
        }
      }
    }
  }
`
